<script>
  import { onMount } from "svelte";
  export let date;

  onMount(async () => {
    const res = await fetch("/api/date");
    const newDate = await res.text();
    date = newDate;
  });
</script>

<main>
  <h1>furious.engineering</h1>
  <div>
    <a href="https://ordinals.furious.engineering">ordinals</a>
  </div>
  <p>{date ? date : 'Loading date...'}</p>
</main>
